import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {Link, useNavigate} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

export default function PayFreeProduct({product}) {
    const redirect =useNavigate()
    const authStore = useAuthStore()
    const [qty, setQty] = useState(product.min_qty)
    const [player_id, setPlayerId] = useState('')
    const [player_name, setPlayerName] = useState('')
    const [note, setNote] = useState('')
    const [totalPrice, setTotalPrice] = useState(product.min_qty * product.unit_price)
    const [errors, setErrors] = useState({qty: ''})
    const [loading, setLoading] = useState(false)
    const onSubmit = async () => {
        setErrors({})
        if(qty>product.max_qty || qty<product.min_qty){
            setErrors({...errors, qty: `يرجى تحديد الكمية بين ${product.min_qty} -> ${product.max_qty}`})
            return;
        }
        if(player_id.length===0){
            setErrors({...errors, player_id: `يرجى إدخال رقم اللاعب`})
            return;
        }
        setLoading(true)
        try {
            const data = new FormData()
            data.append('player_id', player_id)
            data.append('player_name', player_name)
            data.append('product_id', product.id)
            data.append('qty', qty)
            data.append('note', note)
            const response =await baseApi.post('/orders', data)
            if( response.data?.user){
                authStore.setUser(response.data?.user)
                localStorage.setItem('user',JSON.stringify(response.data?.user))

            }
            redirect('/orders')
        } catch (e) {
            setErrors({...errors, msg: e.response.data?.msg})
        }
        setLoading(false)

    }

    useEffect(() => {
        setTotalPrice(product.unit_price * qty);

        if (qty > product.max_qty || qty < product.min_qty) {
            setErrors({...errors, qty: `يرجى تحديد الكمية بين ${product.min_qty} -> ${product.max_qty}`})
        } else {
            setErrors({...errors, qty: ''})
        }

    }, [qty])
    return (
        <div className="col-md-8 ">
            <div className="form mx-3 my-2">
                <p className={'text-orange'}>    <i className="fas fa-caret-left text-orange fa-2x"/> {product.name}</p>

                <ul>
                    {Object.entries(errors).map(([key, value], i) => {
                        if (value) {
                            return <li key={i} className={'text-danger'}>
                                {value}
                            </li>
                        }
                    })}
                </ul>
                <div className=" px-2">
                    <input type="text" onChange={(e)=>setPlayerId(e.target.value)} placeholder={product.type==='id'?'ID الحساب':product.type==='account'?'اسم المستخدم /  البريد الإلكتروني':'رقم الهاتف/رابط الصفحة'}
                           className={'input-form my-2'}/>
                    <input type="text" onChange={(e)=>setPlayerName(e.target.value)} placeholder={product.type==='id'?'اسم الحساب':product.type==='account'?'كلمة المرور':'اسم الحساب'}
                           className={'input-form my-2'}/>

                   <div className="d-flex align-items-center">
                       <input onChange={(e) => setQty(e.target.value)}
                              type="text"
                              onKeyPress={(event) => {
                                  const charCode = event.charCode;
                                  if (charCode < 48 || charCode > 57) {
                                      event.preventDefault();
                                  }
                              }} value={qty}
                              pattern="[0-9]*"
                              placeholder="الكمية"
                              className="input-form my-2 max-width-50"
                       />
                       <input type="text" className="input-form my-2 bg-primary max-width-50" readOnly={'readOnly'}   value={`${authStore.formatCurrency(totalPrice)} `} /><span className="text-white">{authStore.icon==='syr'?'ل.س':''}</span>
                      {/* <span className={'text-orange d-inline-block mx-1 small'}>{totalPrice.toFixed(2)} $</span>*/}
                   </div>
                    <input type="text" onChange={(e)=>setNote(e.target.value)} multiple={4} placeholder={'ملاحظات'} className={'input-form my-2'}/>


                    {!loading ? (
                        authStore.user === null ? (
                            <Link to="/login" className="btn d-block btn-sm submit-btn">تسجيل الدخول</Link>
                        ) : (
                            <button onClick={onSubmit} className="btn d-block btn-sm submit-btn">شراء</button>
                        )
                    ) : (
                        <button className="btn d-block btn-sm submit-btn">جاري الطلب</button>
                    )}


                </div>
            </div>

        </div>
    )
}