import {create} from "zustand";
import {toast} from "react-toastify";

export const useAuthStore = create((set, get) => {

    return {
//
        //
        icon: 'usd',//zstore // extra= coins // other usd
        online: true,//smart,royal=>true
        gradient: true,//extra=>false
        user: null,
        settings: null,
        setUser: (user) => {
            set({user})

        },
        logOut: () => {
            set({user: null})

        },
        setSettings: (settings) => {
            set({settings})

        },
        copyText: (text) => {

            // Copy the text inside the input
            navigator.clipboard.writeText(text).then(function () {
                toast.success('تم النسخ إلى الحافظة')
            }).catch(function (error) {
                toast.error('خطأ في النسخ: ' + error);
            });
        },
        formatCurrency: (number) => {

            const currency = 'usd';//zstore // extra= coins // other usd
            const formatter = new Intl.NumberFormat('en-US', {
              //  style: 'currency',
                currency: currency,
              //  currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: currency === 'SYP' ? 0 : 2,
                maximumFractionDigits: currency === 'SYP' ? 0 : 2,
            });
            if (currency === 'SYP') {
                // return formatter.format(number);
                return formatter.format(number)
            }
            return `${formatter.format(number)} $`


        }
    }
})